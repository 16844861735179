
import { mapActions } from 'pinia'
import Vue from 'vue'
import IconEye from '~/components/UI/icons/cloud/IconEye.vue'
import IconEyeClosed from '~/components/UI/icons/cloud/IconEyeClosed.vue'
import useAuthenticationStore from '~/store/authentication'
import useSessionStore from '~/store/session'

export default Vue.extend({
    components: { IconEye, IconEyeClosed },
    layout: 'login',
    transition: 'slide-fade',
    data() {
        return {
            login: '',
            password: '',
            isLoading: false,
            error: '',
            isRevealed: false,
            rememberUser: true,
            redirectTo: '/'
        }
    },
    head(this: any) {
        return {
            title: this.title
        }
    },
    computed: {
        title() {
            return this.$tc('login.welcome')
        },
        captchaEnabled() {
            return this.$config.CAPTCHA_ENABLED
        },
        CMPBusinessContactPage(): string {
            let urlContact = ''
            switch (this.$i18n.locale) {
                case 'fr':
                    urlContact = 'nous-contacter'
                    break
                case 'de':
                    urlContact = 'de/kontakt'
                    break
                case 'en':
                default:
                    urlContact = 'en/contact-us'
                    break
            }

            return `https://www.chargemap-business.com/${urlContact}`
        }
    },
    mounted(this: any) {
        if (this.$auth.loggedIn && useSessionStore().user) {
            return this.redirect()
        }

        if (this.$route.hash === '#session-redirect') {
            this.$toast?.global?.error({
                title: this.$tc('login.error.token_expired'),
                message: ''
            })
        }

        if (this.$config.CAPTCHA_ENABLED) this.setUpCaptcha()
        this.redirectTo = this.$auth.$storage.getUniversal('redirect') || '/'
    },
    methods: {
        ...mapActions(useAuthenticationStore, ['logUser']),
        setUpCaptcha(this: any) {
            const isBrowser = typeof window !== 'undefined'
            if (isBrowser) {
                try {
                    const windowCaptcha = window as any
                    const recaptchaScript = document.createElement('script')
                    recaptchaScript.type = 'text/javascript'
                    recaptchaScript.async = true
                    recaptchaScript.defer = true
                    recaptchaScript.setAttribute(
                        'src',
                        'https://www.google.com/recaptcha/api.js'
                    )
                    document.head.appendChild(recaptchaScript)
                    windowCaptcha.recaptchaCheck = this.recaptchaCheck
                } catch (error) {
                    this.$sentry.captureMessage('Captcha error')
                }
            }
        },
        handleReveal(this: any) {
            this.isRevealed = !this.isRevealed
        },
        recaptchaCheck(this: any, token: string | undefined) {
            if (!token) {
                this.error = this.$tc('login.error.captcha')
                return
            }
            this.onSubmit(token)
        },
        onSubmit(this: any, token: String): Promise<void> {
            if (this.isLoading) return Promise.resolve()

            if (!this.captchaEnabled) {
                token = ''
            }

            this.isLoading = true
            this.error = ''

            return this.logUser(this, {
                tokenCaptcha: token,
                login: this.login,
                password: this.password
            })
                .then(() => {
                    this.redirect()
                })
                .catch((e: any) => {
                    this.error = e.message ? e.message : ''
                    this.isLoading = false
                })
        },
        redirect(this: any): Promise<void> {
            if (this.redirectTo === '/') {
                return useSessionStore()
                    .initSession(this, null)
                    .then(async (routeRes: any) => {
                        await this.$router.push(routeRes)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
            this.$router.push(this.redirectTo)
            this.isLoading = false
            return Promise.resolve()
        }
    }
})
